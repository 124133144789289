<template>
  <div>
    <div v-if="$route.path === '/index'">
      <!--防止首页过长时，popup层会下拉-->
      <van-search v-model="searchKey" shape="round" background="#fff" disabled placeholder="请输入搜索关键词" @click="goPopup" />
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="darkred">
        <van-swipe-item>1</van-swipe-item>
        <van-swipe-item>2</van-swipe-item>
        <van-swipe-item>3</van-swipe-item>
        <van-swipe-item>4</van-swipe-item>
      </van-swipe>
      <p v-for="item in 99" :key="item">{{ item }}</p>
    </div>
    <div v-if="$route.path === '/index/popup'">
      <transition name="van-slide-right">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchKey: ''
    }
  },
  methods: {
    goPopup() {
      this.$router.push('/index/popup')
    }
  }
}
</script>

<style lang="less" scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}
</style>
